import React, { useState, useEffect } from "react";
import { db } from "./firebaseConfig";
import { collection, addDoc } from "firebase/firestore";

const getBackgroundImage = () => {
  return window.innerWidth < 768
    ? "/Mobile_Background.webp"
    : "/Desktop_Background.webp";
};

const SubscribeForm = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [note, setNote] = useState("");
  const [backgroundImageUrl, setBackgroundImageUrl] = useState(
    getBackgroundImage()
  );

  useEffect(() => {
    const handleResize = () => {
      setBackgroundImageUrl(getBackgroundImage());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "subscribers"), { email,note });
      setMessage("Successfully subscribed!");
      setEmail("");
    } catch (error) {
      console.error("Error subscribing: ", error);
      setMessage("Failed to subscribe. Please try again.");
    }
  };

  return (
    <div
      style={{
        ...styles.container,
        backgroundImage: `url(${backgroundImageUrl})`,
      }}
    >
      <div style={styles.images}>
        <img
          src="https://storage.googleapis.com/crafty-nomad-416703.appspot.com/Logo.webp"
          alt="First"
          style={styles.image}
        />
        <img
          src="https://storage.googleapis.com/crafty-nomad-416703.appspot.com/Banner.webp"
          alt="Second"
          style={styles.image}
        />
      </div>

      <div style={styles.content}>
        <p style={{ color: "white", fontWeight: "bold", margin: "50px 0" }}>
          Coming ada hetama චන්දෙට කලින් scan කරලා හිතල බලන්න ...
        </p>

        <p style={{ color: "white", fontWeight: "bold", margin: "50px 0" }}>
        தேர்தலுக்கு முன் யோசித்துப் பாருங்கள்
        </p>

        

        <form onSubmit={handleSubmit} style={styles.form}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
            style={styles.input}
          />
          <input
            type="text"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder="note"
            required
            style={styles.input}
          />
          <button type="submit" style={styles.button}>
            Subscribe
          </button>
        </form>
        {message && <p style={styles.message}>{message}</p>}
      </div>
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
    color: "#fff",
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start", // Align items to the top
    alignItems: "center",
    padding: "20px 20px 20px 20px", // Adjust padding (top, right, bottom, left)
    marginTop: "0px",
  },
  content: {
    padding: "20px",
    width: "100%",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    padding: "10px",
    fontSize: "16px",
    marginBottom: "10px",
    border: "none",
    borderRadius: "25px", // Rounded corners
    backgroundColor: "#003366", // Dark blue background
    color: "#D3D3D3", // Gray text color
    width: "100%",
    maxWidth: "400px",
    outline: "none", // Remove default outline
    boxSizing: "border-box", // Include padding and border in the element's total width and height
    textAlign: "center",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    color: "#fff",
    backgroundColor: "#960563",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  message: {
    marginTop: "20px",
    fontSize: "18px",
  },
  images: {
    display: "flex",
    flexDirection: "column", // Stack images vertically
    alignItems: "center", // Center images horizontally
    marginBottom: "20px", // Space between images and form
    marginTop:"40px",
  },
  image: {
    width: "90%", // Adjust width based on needs
    maxWidth: "600px", // Limit maximum width
    marginBottom: "10px", // Space between images
  },
};

export default SubscribeForm;
