// src/App.js
import React from 'react';
import SubscribeForm from './SubscribeForm';

function App() {
  return (
    <div className="App">
      <SubscribeForm />
    </div>
  );
}

export default App;
