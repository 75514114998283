// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

import { getAuth, signInAnonymously, onAuthStateChanged } from 'firebase/auth';


/**
 * 
 * const firebaseConfig = {
  apiKey: "YOUR_API_KEY",
  authDomain: "YOUR_AUTH_DOMAIN",
  projectId: "YOUR_PROJECT_ID",
  storageBucket: "YOUR_STORAGE_BUCKET",
  messagingSenderId: "YOUR_MESSAGING_SENDER_ID",
  appId: "YOUR_APP_ID"
};

 */


const firebaseConfig = {
    apiKey: "AIzaSyCmLAiOwI-SC-K2t3obvMMzlTKpF5OVaSs",
    authDomain: "crafty-nomad-dev.firebaseapp.com",
    projectId: "crafty-nomad-dev",
    storageBucket: "crafty-nomad-dev.appspot.com",
    messagingSenderId: "629721050365",
    appId: "1:629721050365:web:6ae93e78a0b485d77ea294",
    measurementId: "G-2GSVH3VJZ6"
  };


  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);
  
  signInAnonymously(auth)
    .then(() => {
      console.log('Signed in anonymously');
    })
    .catch((error) => {
      console.error('Error signing in anonymously', error);
    });



    onAuthStateChanged(auth, (user) => {
        if (user) {
          user.getIdTokenResult()
            .then((idTokenResult) => {
              if (!!idTokenResult.claims.formAccess) {
                console.log('User has form Access claim');
              } else {
                console.log('User does not have form Access claim');
              }
            })
            .catch((error) => {
              console.error('Error getting token result:', error);
            });
        }
      });
  
  export { db };